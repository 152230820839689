// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or vendor/assets/javascripts of plugins, if any, can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/sstephenson/sprockets#sprockets-directives) for details
// about supported directives.
//

// JQuery
//= require jquery
//= require jquery-ui/core
//= require jquery-ui/widgets/datepicker
//= require jquery-ui/i18n/datepicker-fr
//= require jquery-ui/widgets/autocomplete
//= require jquery.flexslider
//= require js.cookie
//= require partials/jquery.scrollspy

// Leaflet
//= require leaflet
//= require leaflet-markercluster

// Code HAP
//= require partials/_helpers
//= require partials/vars
//= require partials/ui
//= require partials/core
//= require partials/autocomplete
//= require partials/hotel_reviews
//= require partials/hotel_availabilities
//= require partials/hotel_comparator
//= require partials/sliders
//= require partials/page_search
//= require partials/maps
//= require partials/geocoder
//= require partials/header_bg
//= require partials/stpo
//= require partials/form_validator
//= require partials/search_extension
//= require partials/transport_line

jQuery(document).ready(function () {
  HAP.core.boot();
});
